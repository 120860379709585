import axios, { AxiosInstance, AxiosResponse } from "axios";
import { LoginResponse } from "../@Types/DRO/LoginResponse";
import {
  dashBoardFrontCardResponse,
  merchatListResponse,
} from "../@Types/DRO/HomePageResponse";
export class OrderOnDemandApi {
  public static api = new OrderOnDemandApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://pre-micro.bcykal.com",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  loginApi(
    username: String,
    password: String
  ): Promise<AxiosResponse<LoginResponse>> {
    return axios.post<LoginResponse>(
      "https://pre-micro.bcykal.com/mobycy/on-demand/v1/login",
      {
        username,
        password,
      }
    );
  }

  getToken() {
    return localStorage.getItem("token");
  }

  dashboardFrontCardDetails(
    startDate: string | undefined,
    endDate: string | undefined
  ): Promise<AxiosResponse<dashBoardFrontCardResponse>> {
    return this.instance.get<dashBoardFrontCardResponse>(
      "/mobycy/on-demand/dashboard/v1/order-header",
      {
        params: {
          startDate,
          endDate,
        },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
  merchantList(): Promise<AxiosResponse<merchatListResponse>> {
    return this.instance.get<merchatListResponse>(
      "/mobycy/on-demand/dashboard/v1/on-demand/merchants",
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
}
