import axios, { AxiosInstance, AxiosResponse } from "axios";
import { RetryOrderResponse } from "../@Types/DRO/RetryOrderResponse";
import {
  NearbyDriverListResponse,
  OrderResponse,
} from "../@Types/DRO/CancelOrderResponse";
import { orderStatusTimeStampResponse } from "../@Types/DRO/HomePageResponse";

export class OrderStatusApi {
  public static api = new OrderStatusApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://pre-micro.bcykal.com",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  cancelOrder(orderNumber: number, remark: string): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/order/cancel`,
        {
          orderNumber,
          remark,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }

  reAssignOrder(
    orderNumber: number,
    riderId: number,
    remark: string
  ): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/order/re-assign`,
        {
          orderNumber,
          riderId,
          remark,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }

  unAssignOrder(orderNumber: number, remark: string): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/order/un-assign`,
        {
          orderNumber,
          remark,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }

  updateOrder(
    orderNumber: number,
    sourceLat: number,
    sourceLng: number,
    destinationLat: number,
    destinationLng: number,
    remark: string
  ): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/order/update`,
        {
          orderNumber,
          sourceLat,
          sourceLng,
          destinationLat,
          destinationLng,
          remark,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }

  updateOrderStatus(
    orderNumber: number,
    orderStatus: number,
    communicationFlag: number,
    remarks: string
  ): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/order-status/update`,
        {
          orderNumber,
          orderStatus,
          communicationFlag,
          remarks,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }

  nearbyDriverList(
    orderNumber: number
  ): Promise<AxiosResponse<NearbyDriverListResponse>> {
    return this.instance.get<NearbyDriverListResponse>(
      `/mobycy/on-demand/dashboard/v1/nearby/rider-list`,
      {
        params: {
          orderNumber,
        },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
  trackOrderStatusTimeStamp(
    orderNumber: number
  ): Promise<AxiosResponse<orderStatusTimeStampResponse>> {
    return this.instance.get<orderStatusTimeStampResponse>(
      `/mobycy/on-demand/dashboard/v1/order-status-list`,
      {
        params: {
          orderNumber,
        },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
  callRider(riderId: string): Promise<any> {
    return this.instance
      .post<any>(
        `/mobycy/on-demand/dashboard/v1/call-rider`,
        {
          riderId,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  calling(
    contactType: number,
    orderNumber: number,
    riderId: number | null
  ): Promise<any> {
    type RequestBody = {
      orderNumber: number;
      contactType: number;
      riderId?: number;
    };
    const requestBody: RequestBody = {
      orderNumber,
      contactType,
    };

    if (contactType === 3 && riderId) {
      requestBody.riderId = riderId;
    }
    return this.instance
      .post<any>(
        `/mobycy/on-demand/dashboard/v1/call`,

        requestBody,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
