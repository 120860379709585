import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  breachedOrdersHeaderResponseProps,
  breachedOrdersListResponseProps,
} from "../@Types/DRO/BreachedOrdersResponse";
export class BreachedOrdersApi {
  public static api = new BreachedOrdersApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://pre-micro.bcykal.com/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  breachedOrdersHeaders(): Promise<
    AxiosResponse<breachedOrdersHeaderResponseProps>
  > {
    let apiUrl = "mobycy/on-demand/dashboard/v1/breached-order-header";
    return this.instance.get<breachedOrdersHeaderResponseProps>(apiUrl, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  breachedOrdersList(
    selectedStatus: string,
    searchParam: string
  ): Promise<AxiosResponse<breachedOrdersListResponseProps>> {
    let apiUrl = "mobycy/on-demand/dashboard/v1/breached-order-details";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance.get<breachedOrdersListResponseProps>(apiUrl, {
      params: {
        type: selectedStatus,
      },
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
}
